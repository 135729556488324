//////////////For Admin////////////////////
const ADMINHOME='/admin';
const MAINHOME='/';
export const NavigationPaths = {
  HOME: ADMINHOME,
  ONBOARDING: ADMINHOME+'/dashboard',
  LISTUSER: ADMINHOME+'/listUser',
  ADDUSER: ADMINHOME+'/addUser',
  EDITUSER: ADMINHOME+'/editUser',
  EDITPROFILE: ADMINHOME+'/editProfile',
  EDITPASSWORD: ADMINHOME+'/editPassword',
  ///////////News Paths/////////
  LISTNEWS: ADMINHOME+'/listNews',
  ADDNEWS: ADMINHOME+'/addNews',
  EDITNEWS: ADMINHOME+'/editNews',
  ///////////Event Paths/////////
  LISTEVENT: ADMINHOME+'/listEvent',
  ADDEVENT: ADMINHOME+'/addEvent',
  EDITEVENT: ADMINHOME+'/editEvent',
  ///////////Event Gallery Paths/////////
  LISTGALLERY: ADMINHOME+'/listGallery',
  ADDGALLERY: ADMINHOME+'/addGallery',
  EDITGALLERY: ADMINHOME+'/editGallery',
  ///////////Team Paths/////////
  LISTTEAM: ADMINHOME+'/listTeam',
  ADDTEAM: ADMINHOME+'/addTeam',
  EDITTEAM: ADMINHOME+'/editTeam',
  ///////////Player Paths/////////
  LISTPLAYER: ADMINHOME+'/listPlayer',
  ADDPLAYER: ADMINHOME+'/addPlayer',
  EDITPLAYER: ADMINHOME+'/editPlayer',
  ASSIGNPLAYER: ADMINHOME+'/assignPlayer',
  ///////////Tournament Paths/////////
  LISTTOURNAMENT: ADMINHOME+'/listTournament',
  ADDTOURNAMENT: ADMINHOME+'/addTournament',
  EDITTOURNAMENT: ADMINHOME+'/editTournament',
  ///////////Match Paths/////////
  LISTMATCH: ADMINHOME+'/listMatch',
  ADDMATCH: ADMINHOME+'/addMatch',
  EDITMATCH: ADMINHOME+'/editMatch',
  ///////////Gem Category Paths/////////
  LISTGEMCAT: ADMINHOME+'/listGemcat',
  ADDGEMCAT: ADMINHOME+'/addGemcat',
  EDITGEMCAT: ADMINHOME+'/editGemcat',
  ///////////Gem Paths/////////
  LISTGEM: ADMINHOME+'/listGem',
  ADDGEM: ADMINHOME+'/addGem',
  EDITGEM: ADMINHOME+'/editGem',
  ///////////Activity Paths/////////
  LISTACTIVITY: ADMINHOME+'/listActivity',
  ADDACTIVITY: ADMINHOME+'/addActivity',
  EDITACTIVITY: ADMINHOME+'/editActivity',
  ///////////Activity Gallery Paths/////////
  LISTACTGALLERY: ADMINHOME+'/listActgallery',
  ADDACTGALLERY: ADMINHOME+'/addActgallery',
  EDITACTGALLERY: ADMINHOME+'/editActgallery',
  ///////////Video Category Paths/////////
  LISTVIDEOCAT: ADMINHOME+'/listVideocat',
  ADDVIDEOCAT: ADMINHOME+'/addVideocat',
  EDITVIDEOCAT: ADMINHOME+'/editVideocate',
  ///////////Video Gallery Paths/////////
  LISTVIDEOGALLERY: ADMINHOME+'/listVideogallery',
  ADDVIDEOGALLERY: ADMINHOME+'/addVideogallery',
  EDITVIDEOGALLERY: ADMINHOME+'/editVideogallery',
  ///////////CMS Paths/////////
  LISTCMS: ADMINHOME+'/listCms',
  EDITCMS: ADMINHOME+'/editCms',
  ///////////Unit Paths/////////
  LISTUNIT: ADMINHOME+'/listUnit',
  ADDUNIT: ADMINHOME+'/addUnit',
  EDITUNIT: ADMINHOME+'/editUnit',
  ///////////Commitee Paths/////////
  LISTCOMMITYCAT: ADMINHOME+'/listCommityCat',
  ADDCOMMITYCAT: ADMINHOME+'/addCommityCat',
  EDITCOMMITYCAT: ADMINHOME+'/editCommityCat',
  LISTCOMMITYSUBCAT: ADMINHOME+'/listCommitySubcat',
  ADDCOMMITYSUBCAT: ADMINHOME+'/addCommitySubcat',
  EDITCOMMITYSUBCAT: ADMINHOME+'/editCommitySubcat',
  LISTCOMMITYMEMBER: ADMINHOME+'/listCommityMember',
  ADDCOMMITYMEMBER: ADMINHOME+'/addCommityMember',
  EDITCOMMITYMEMBER: ADMINHOME+'/editCommityMember',
  ///////////Award Paths/////////
  LISTAWARD: ADMINHOME+'/listAward',
  ADDAWARD: ADMINHOME+'/addAward',
  EDITAWARD: ADMINHOME+'/editAward',
  ///////////Award Gallery Paths/////////
  LISTAWARDGALLERY: ADMINHOME+'/listAwardgallery',
  ADDAWARDGALLERY: ADMINHOME+'/addAwardgallery',
  EDITAWARDGALLERY: ADMINHOME+'/editAwardgallery',
  ///////////Contact Paths/////////
  LISTCONTACT: ADMINHOME+'/listContact',
  ///////////Ground Paths/////////
  LISTGROUND: ADMINHOME+'/listGround',
  ADDGROUND: ADMINHOME+'/addGround',
  EDITGROUND: ADMINHOME+'/editGround',
  ///////////Banner Paths/////////
  LISTBANNER: ADMINHOME+'/listBanner',
  ADDBANNER: ADMINHOME+'/addBanner',
  EDITBANNER: ADMINHOME+'/editBanner',
  ///////////Team Paths/////////
};
///////////////For Admin//////////////////
export const APIPaths = {
  /////User Related Paths///////
  LOGIN: '/api/admin/auth/login',
  CREATEUSER: '/api/admin/user/createUser',
  FETCHALLUSER: '/api/admin/user/fetchAll',
  EDITUSER: '/api/admin/user/editUser',
  DELETEUSER: '/api/admin/user/deleteUser',
  GETUSERBYID: '/api/admin/user/getUserById',
  GETACTIVEUSERS: '/api/admin/user/fetchActiveUsers',
  GETMYDETAILS: '/api/admin/user/getMyProfile',
  EDITMYPROFILE: '/api/admin/user/editMyProfile',
  UPLOADUSERIMG: '/api/admin/user/uploadUserImage',
  EDITMYPASSWORD: '/api/admin/user/editMyPassword',
  ////////News Paths///////////
  CREATENEWS: '/api/admin/news/createNews',
  FETCHALLNEWS: '/api/admin/news/fetchAll',
  EDITNEWS: '/api/admin/news/editNews',
  DELETENEWS: '/api/admin/news/deleteNews',
  GETNEWSBYID: '/api/admin/news/getNewsById',
  UPLOADNEWSFILE: '/api/admin/news/uploadFile',
  UPLOADNEWSIMAGE: '/api/admin/news/uploadImage',
  ////////Event Paths///////////
  CREATEEVENT: '/api/admin/event/createEvent',
  FETCHALLEVENT: '/api/admin/event/fetchAll',
  EDITEVENT: '/api/admin/event/editEvent',
  DELETEEVENT: '/api/admin/event/deleteEvent',
  GETEVENTBYID: '/api/admin/event/getEventById',
  UPLOADEVENTFILE: '/api/admin/event/uploadFile',
  GETACTIVEEVENTS: '/api/admin/event/fetchActiveEvents',
  ////////Event Gallery Paths///////////
  CREATEGALLERY: '/api/admin/event/createGallery',
  FETCHALLGALLERY: '/api/admin/event/fetchEventGallery',
  EDITGALLERY: '/api/admin/event/editGallery',
  DELETEGALLERY: '/api/admin/event/deleteGallery',
  GETGALLERYBYID: '/api/admin/event/getGalleryById',
  UPLOADGALLERYFILE: '/api/admin/event/uploadGalleryFile',
  GETACTIVEGALLERY: '/api/admin/event/fetchActiveGallery',
  ////////Team Paths///////////
  CREATETEAM: '/api/admin/team/createTeam',
  FETCHALLTEAM: '/api/admin/team/fetchAll',
  EDITTEAM: '/api/admin/team/editTeam',
  DELETETEAM: '/api/admin/team/deleteTeam',
  GETTEAMBYID: '/api/admin/team/getTeamById',
  GETACTIVETEAMS: '/api/admin/team/fetchActiveTeams',
  COUNTACTIVETEAMS: '/api/admin/team/countActiveTeams',
  UPLOADTEAMIMAGE: '/api/admin/team/uploadImage',
  ////////Team Player Paths///////////
  CREATEPLAYER: '/api/admin/team/createPlayer',
  FETCHALLPLAYER: '/api/admin/team/fetchTeamPlayer',
  EDITPLAYER: '/api/admin/team/editPlayer',
  DELETEPLAYER: '/api/admin/team/deletePlayer',
  GETPLAYERBYID: '/api/admin/team/getPlayerById',
  GETACTIVEPLAYER: '/api/admin/team/fetchActivePlayer',
  COUNTACTIVEPLAYER: '/api/admin/team/countActivePlayer',
  ASSIGNPLAYERS: '/api/admin/team/createMultiPlayer',
  GETUNASSIGNPLAYERS: '/api/admin/team/getUnassignPlayers',
  UPLOADPLAYERIMAGE: '/api/admin/team/uploadPlayerImage',
  ////////Tournament Paths///////////
  CREATETOURNAMENT: '/api/admin/tournament/createTournament',
  FETCHALLTOURNAMENT: '/api/admin/tournament/fetchAll',
  EDITTOURNAMENT: '/api/admin/tournament/editTournament',
  DELETETOURNAMENT: '/api/admin/tournament/deleteTournament',
  GETTOURNAMENTBYID: '/api/admin/tournament/getTournamentById',
  GETACTIVETOURNAMENTS: '/api/admin/tournament/fetchActiveTournaments',
  ////////Match Paths///////////
  CREATEMATCH: '/api/admin/tournament/createMatch',
  FETCHALLMATCH: '/api/admin/tournament/fetchTournamentMatch',
  EDITMATCH: '/api/admin/tournament/editMatch',
  DELETEMATCH: '/api/admin/tournament/deleteMatch',
  GETMATCHBYID: '/api/admin/tournament/getMatchById',
  GETACTIVEMATCH: '/api/admin/tournament/fetchActiveMatch',
  ////////Gem Category Paths///////////
  CREATEGEMCAT: '/api/admin/gem/createCategory',
  FETCHALLGEMCAT: '/api/admin/gem/fetchAllCategory',
  EDITGEMCAT: '/api/admin/gem/editCategory',
  DELETEGEMCAT: '/api/admin/gem/deleteCategory',
  GETGEMCATBYID: '/api/admin/gem/getCategoryById',
  GETACTIVEGEMCAT: '/api/admin/gem/fetchActiveCategory',
  ////////Gem Paths///////////
  CREATEGEM: '/api/admin/gem/createGem',
  FETCHALLGEM: '/api/admin/gem/fetchGems',
  EDITGEM: '/api/admin/gem/editGem',
  DELETEGEM: '/api/admin/gem/deleteGem',
  GETGEMBYID: '/api/admin/gem/getGemById',
  GETACTIVEGEM: '/api/admin/gem/fetchActiveGem',
  COUNTACTIVEGEM: '/api/admin/gem/countActiveGem',
  ////////Activity Paths///////////
  CREATEACTIVITY: '/api/admin/activity/createActivity',
  FETCHALLACTIVITY: '/api/admin/activity/fetchAll',
  EDITACTIVITY: '/api/admin/activity/editActivity',
  DELETEACTIVITY: '/api/admin/activity/deleteActivity',
  GETACTIVITYBYID: '/api/admin/activity/getActivityById',
  GETACTIVEACTIVITIES: '/api/admin/activity/fetchActiveActivities',
  ////////Activity Gallery Paths///////////
  CREATEACTGALLERY: '/api/admin/activity/createGallery',
  FETCHALLACTGALLERY: '/api/admin/activity/fetchActivityGallery',
  EDITACTGALLERY: '/api/admin/activity/editGallery',
  DELETEACTGALLERY: '/api/admin/activity/deleteGallery',
  GETACTGALLERYBYID: '/api/admin/activity/getGalleryById',
  UPLOADACTGALLERYFILE: '/api/admin/activity/uploadGalleryFile',
  GETACTIVEACTGALLERY: '/api/admin/activity/fetchActiveGallery',
  ////////Video Category Paths///////////
  CREATEVIDEOCAT: '/api/admin/video/createVideocat',
  FETCHALLVIDEOCAT: '/api/admin/video/fetchAllvideocat',
  EDITVIDEOCAT: '/api/admin/video/editVideocat',
  DELETEVIDEOCAT: '/api/admin/video/deleteVideocat',
  GETVIDEOCATBYID: '/api/admin/video/getVideocatById',
  GETACTIVEVIDEOCAT: '/api/admin/video/fetchActiveVideocat',
  UPLOADVIDTHUMBIMAGE: '/api/admin/video/uploadVidImage',
  ////////Activity Gallery Paths///////////
  CREATEVIDEOGALLERY: '/api/admin/video/createVideoGallery',
  FETCHALLVIDEOGALLERY: '/api/admin/video/fetchVideoGallery',
  EDITVIDEOGALLERY: '/api/admin/video/editVideoGallery',
  DELETEVIDEOGALLERY: '/api/admin/video/deleteVideoGallery',
  GETVIDEOGALLERYBYID: '/api/admin/video/getVideoGalleryById',
  UPLOADVIDEOGALLERYFILE: '/api/admin/video/uploadVideoGallery',
  GETACTIVEVIDEOGALLERY: '/api/admin/video/fetchActiveVideoGallery',
  /////CMS PAths//////////
  FETCHALLCMS: '/api/admin/cms/fetchAll',
  EDITCMS: '/api/admin/cms/editCms',
  GETCMSBYID: '/api/admin/cms/getCmsById',
  UPLOADCMSIMAGE: '/api/admin/cms/uploadImage',
  /////Unit PAths//////////
  FETCHALLUNIT: '/api/admin/common/fetchAllUnits',
  ADDUNIT: '/api/admin/common/createUnit',
  EDITUNIT: '/api/admin/common/editUnit',
  GETUNITBYID: '/api/admin/common/getUnitById',
  DELETEUNIT: '/api/admin/common/deleteUnit',
  GETACTIVEUNITS: '/api/common/getActiveUnits',
  /////Commity PAths//////////
  FETCHALLCOMMITYCAT: '/api/admin/common/fetchAllCommityCat',
  ADDCOMMITYCAT: '/api/admin/common/createCommityCat',
  EDITCOMMITYCAT: '/api/admin/common/editCommityCat',
  GETCOMMITYCATBYID: '/api/admin/common/getCommityCatById',
  DELETECOMMITYCAT: '/api/admin/common/deleteCommityCat',
  GETACTIVECOMMITYCATS: '/api/common/getActiveCommityCats',

  FETCHALLCOMMITYSUBCAT: '/api/admin/common/fetchAllCommitySubcat',
  ADDCOMMITYSUBCAT: '/api/admin/common/createCommitySubcat',
  EDITCOMMITYSUBCAT: '/api/admin/common/editCommitySubcat',
  GETCOMMITYSUBCATBYID: '/api/admin/common/getCommitySubcatById',
  DELETECOMMITYSUBCAT: '/api/admin/common/deleteCommitySubcat',
  GETACTIVECOMMITYSUBCATS: '/api/common/getActiveCommitySubcats',


  FETCHALLCOMMITYMEMEBR: '/api/admin/common/fetchAllCommityMember',
  ADDCOMMITYMEMBER: '/api/admin/common/createCommityMember',
  EDITCOMMITYMEMBER: '/api/admin/common/editCommityMember',
  GETCOMMITYMEMBERBYID: '/api/admin/common/getCommityMemberById',
  DELETECOMMITYMEMBER: '/api/admin/common/deleteCommityMember',
  GETACTIVECOMMITYMEMBERS: '/api/common/getActiveCommityMembers',
  UPLOADCOMMITYMEMBERIMG: '/api/admin/common/uploadMemberImage',
  COUNTACTIVECOMMITYMEMBERS: '/api/common/countActiveCommityMembers',
  ////////Award Paths///////////
  CREATEAWARD: '/api/admin/award/createAward',
  FETCHALLAWARD: '/api/admin/award/fetchAll',
  EDITAWARD: '/api/admin/award/editAward',
  DELETEAWARD: '/api/admin/award/deleteAward',
  GETAWARDBYID: '/api/admin/award/getAwardById',
  GETACTIVEAWARDS: '/api/admin/award/fetchActiveAwards',
  ////////Award Gallery Paths///////////
  CREATEAWARDGALLERY: '/api/admin/award/createGallery',
  FETCHALLAWARDGALLERY: '/api/admin/award/fetchAwardGallery',
  EDITAWARDGALLERY: '/api/admin/award/editGallery',
  DELETEAWARDGALLERY: '/api/admin/award/deleteGallery',
  GETAWARDGALLERYBYID: '/api/admin/award/getGalleryById',
  UPLOADAWARDGALLERYFILE: '/api/admin/award/uploadGalleryFile',
  GETACTIVEAWARDGALLERY: '/api/admin/award/fetchActiveGallery',
  ////////Contact Paths///////////
  FETCHALLCONTACT: '/api/admin/contact/fetchAllContact',
  DELETECONTACT: '/api/admin/contact/deleteContact',
  /////Ground PAths//////////
  FETCHALLGROUND: '/api/admin/common/fetchAllGrounds',
  ADDGROUND: '/api/admin/common/createGround',
  EDITGROUND: '/api/admin/common/editGround',
  GETGROUNDBYID: '/api/admin/common/getGroundById',
  DELETEGROUND: '/api/admin/common/deleteGround',
  GETACTIVEGROUNDS: '/api/common/getActiveGrounds',
  ////////Banner Paths///////////
  CREATEBANNER: '/api/admin/banner/createBanner',
  FETCHALLBANNER: '/api/admin/banner/fetchBanner',
  EDITBANNER: '/api/admin/banner/editBanner',
  DELETEBANNER: '/api/admin/banner/deleteBanner',
  GETBANNERBYID: '/api/admin/banner/getBannerById',
  UPLOADBANNERFILE: '/api/admin/banner/uploadBannerFile',
  GETACTIVEBANNER: '/api/admin/banner/fetchActiveBanner',
};

///////Path for FrontEnd////////////
export const commonVar={
  ACCESSVAL:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhc2NrZXkiOiJBU0NBU1NBTSIsImlhdCI6MTY4NzIwMjQxN30.gXXMPGClRj_A35UTjUPOgWMWxOXobRr5HDXwAcQH-4c'
};
export const frontNavPaths={
  HOME:MAINHOME,
  GALLERY: MAINHOME+'gallery',
  NEWSDETAILS: MAINHOME+'newsDetails',
  ACTIVITYDETAILS: MAINHOME+'actDetails',
  GETPLAYERSBYTEAM: MAINHOME+'players',
  PLAYERDETAILSBYID: MAINHOME+'playerDetails',
  MATCHES: MAINHOME+'matches',
  GETUPMATCHES: MAINHOME+'upmatches',
  TEAMS: MAINHOME+'teams',
  EVENTS: MAINHOME+'events',
  NEWS: MAINHOME+'news',
  ACTIVITIES: MAINHOME+'activities',
  GEMS: MAINHOME+'gems',
  GEMDETAILSBYID: MAINHOME+'gemDetails',
  CONTACT: MAINHOME+'contact',
  CMS: MAINHOME+'cms',
  UNITS: MAINHOME+'units',
  COMMITEE: MAINHOME+'committee',
  MEMBERDETAILSBYID: MAINHOME+'memberDetails',
  VIDEOGALLERIES: MAINHOME+'videos',
  AWARDS: MAINHOME+'award',
  AWARDDETAILS: MAINHOME+'awardDetails',
  GROUNDS: MAINHOME+'grounds',
};
export const frontApiPaths={
  GETACTIVEEVENTS: '/api/event/getActiveEvents',
  GETACTIVENEWS: '/api/news/getActiveNews',
  GETACTIVEACTGALLERY: '/api/activity/getActiveGallery',
  GETACTIVEMATCH: '/api/tournament/fetchActiveMatch',
  GETACTIVEVIDEOGALLERY: '/api/video/getActiveVideoGallery',
  GETUPCOMINGMATCH: '/api/tournament/fetchUpcomingMatch',
  GETSIDEACTIVETEAMS: '/api/team/getActiveTeams',
  GETACTIVETEAMS: '/api/team/fetchActiveTeams',
  GETACTIVEPLAYER: '/api/team/getActivePlayer',
  GETACTIVEGALLERY: '/api/event/getActiveGallery',
  GETACTIVEACTIVITIES: '/api/activity/getActiveActivities',
  GETNEWSBYID: '/api/news/getNewsById',
  GETACTIVITYBYID: '/api/activity/getActivityById',
  GETPLAYERBYID: '/api/team/getPlayerById',
  GETACTIVETOURNAMENTS: '/api/tournament/getActiveTournaments',
  GETACTIVEGEMCAT: '/api/gem/getActiveCategory',
  GETACTIVEGEM: '/api/gem/getActiveGem',
  GETGEMBYID: '/api/gem/getGemById',
  GETCMSBYSLUG: '/api/cms/getCmsBySlug',
  GETACTIVEUNITS: '/api/common/getActiveUnits',
  GETACTIVECOMMITYCATS: '/api/common/getActiveCommityCats',
  GETACTIVECOMMITYSUBCATS: '/api/common/getActiveCommitySubcats',
  GETACTIVECOMMITYMEMBERS: '/api/common/getActiveCommityMembers',
  GETACTIVECOMMITYCATSANDSUBCATS: '/api/common/getActiveCommityCatandSubcats',
  GETMEMBERBYID: '/api/common/getMemById',
  GETACTIVEACWARDS: '/api/award/getActiveAwards',
  GETAWARDBYID: '/api/award/getAwardById',
  GETACTIVEAWARDGALLERY: '/api/award/getActiveGallery',
  CREATECONTACT: '/api/contact/createContact',
  GETACTIVEGROUNDS: '/api/common/getActiveGrounds',
  GETACTIVEBANNER: '/api/banner/getActiveBanner',
  GETACTIVENOTI: '/api/news/getActiveNoti',
};
